.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid grey;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.component {
    opacity: 0.2;
    transform: scale(0.9) translateY(-20px);
    animation: fadeIn 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0.2;
        transform: scale(0.9) translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

.componentCircle {
    opacity: 0.8;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9) translateY(-20px) rotate(-20deg);
    animation: fadeCircle 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes fadeCircle {
    0% {
        opacity: 0.8;
        transform: translate(-50%, -50%) scale(0.9) translateY(-20px) rotate(-20deg);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) translateY(0) rotate(0);
    }
}