.card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.card-plain {
    box-shadow: none;
    border: 1px solid #e0e0e0;
}

.card-profile {
    text-align: center;
}

.card-blog {
    margin: 16px 0;
}

.card-raised {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-background {
    background-size: cover;
    background-position: center;
}

.card-pricing-color {
    color: #fff;
}

.card-primary {
    background-color: #007bff;
}

.card-info {
    background-color: #17a2b8;
}

.card-success {
    background-color: #28a745;
}

.card-warning {
    background-color: #ffc107;
}

.card-danger {
    background-color: #dc3545;
}

.card-rose {
    background-color: #e91e63;
}

.card-pricing {
    text-align: center;
    padding: 32px;
}

.card-product {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-chart {
    padding: 0;
}

.card-login {
    width: 400px;
    margin: 0 auto;
}