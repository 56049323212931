.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    padding: 33px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gridContainer {
    max-width: 900px;
    background-color: #fafbfc;
    border-radius: 5px;
    height: 784px;
    overflow: hidden;
    border: 2px solid #dedede;
    position: relative;
    margin: auto;
}

.box {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: transparent;
    z-index: 0;
}

.closeIcon {
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 5%;
}

.title {
    margin-left: 35px;
    margin-top: 30px;
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin-bottom: 30px;
}

.filter {
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: 12px;
    cursor: pointer;
    color: grey;
    display: flex;
    margin-top: 5px;
    margin-left: 22px;
    margin-right: 22px;
    align-self: center;
    background-color: white;
}


.filter p {
    padding: 0px;
    font-size: 16px;
    font-weight: bolder;
    margin: 0px;
    margin-left: 15px;
    background-color: transparent;
    align-self: center;
}

.filterItemBox {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    overflow-y: scroll;
    max-height: 664px;
    width: 102.5%;
    padding-bottom: 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding-left: 15px;
    padding-right: 15px;
}

.filterItem {
    width: 108%;
    padding: 0px;
    margin-left: -5%;
    margin-bottom: 5%;
    height: 200px;
    cursor: pointer;
}

.previewContainer {
    height: 100%;
    border: 1px solid #ebebeb;
}

.preview {
    height: 100%;
}

.grid {
    padding: 0px;
    margin: 0px;
}

.gridBox {
    padding: 0px;
    margin: 0px;
    position: relative;
}

.boxIncrease {
    transition: 0.2s
}

.boxIncrease:hover {
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 10px;
}

.list {
    background-color: transparent;
    padding-top: 20px;
    margin-left: 10px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.header {
    border-bottom: 1px solid #d1d1cf;
    padding: 0px;
}

/* .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    background-color: .7;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
} */