.maskHide{
    position: relative;
    z-index: 1;
    color: rgba(0, 0, 0, 0.0); 
}

.mask{
    position: relative;
    z-index: 1;
    color: rgba(0, 0, 0, 0.3); 
}

.step{
    font-size: 12px;
    font-weight: bolder;
    border-radius: 5px;
    width: 20vw;
    color: #333;
    box-shadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px";
    font-family: Helvetica, Arial, sans-serif;
}
