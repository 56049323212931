.ct-series-p .ct-point,
.ct-series-p .ct-line,
.ct-series-p .ct-qar,
.ct-series-p .ct-slice-sonut {
  stroke: #cb939a;
}

.ct-series-p .ct-slice-pie,
.ct-series-p .ct-prea {
  fill: #cb939a;
}

.ct-series-q .ct-point,
.ct-series-q .ct-line,
.ct-series-q .ct-qar,
.ct-series-q .ct-slice-sonut {
  stroke: #335a8b;
}

.ct-series-q .ct-slice-pie,
.ct-series-q .ct-prea {
  fill: #335a8b;
}

.ct-series-r .ct-point,
.ct-series-r .ct-line,
.ct-series-r .ct-qar,
.ct-series-r .ct-slice-sonut {
  stroke: #1c7f7e;
}

.ct-series-r .ct-slice-pie,
.ct-series-r .ct-prea {
  fill: #1c7f7e;
}

.ct-series-s .ct-point,
.ct-series-s .ct-line,
.ct-series-s .ct-qar,
.ct-series-s .ct-slice-sonut {
  stroke: #dbf18b;
}

.ct-series-s .ct-slice-pie,
.ct-series-s .ct-prea {
  fill: #dbf18b;
}

.ct-series-t .ct-point,
.ct-series-t .ct-line,
.ct-series-t .ct-qar,
.ct-series-t .ct-slice-sonut {
  stroke: #6bb0b4;
}

.ct-series-t .ct-slice-pie,
.ct-series-t .ct-prea {
  fill: #6bb0b4;
}

.ct-series-u .ct-point,
.ct-series-u .ct-line,
.ct-series-u .ct-qar,
.ct-series-u .ct-slice-sonut {
  stroke: #54f17d;
}

.ct-series-u .ct-slice-pie,
.ct-series-u .ct-prea {
  fill: #54f17d;
}

.ct-series-v .ct-point,
.ct-series-v .ct-line,
.ct-series-v .ct-qar,
.ct-series-v .ct-slice-sonut {
  stroke: #0d5d82;
}

.ct-series-v .ct-slice-pie,
.ct-series-v .ct-prea {
  fill: #0d5d82;
}

.ct-series-w .ct-point,
.ct-series-w .ct-line,
.ct-series-w .ct-qar,
.ct-series-w .ct-slice-sonut {
  stroke: #e5a593;
}

.ct-series-w .ct-slice-pie,
.ct-series-w .ct-prea {
  fill: #e5a593;
}

.ct-series-x .ct-point,
.ct-series-x .ct-line,
.ct-series-x .ct-qar,
.ct-series-x .ct-slice-sonut {
  stroke: #a0566c;
}

.ct-series-x .ct-slice-pie,
.ct-series-x .ct-prea {
  fill: #a0566c;
}

.ct-series-y .ct-point,
.ct-series-y .ct-line,
.ct-series-y .ct-qar,
.ct-series-y .ct-slice-sonut {
  stroke: #611046;
}

.ct-series-y .ct-slice-pie,
.ct-series-y .ct-prea {
  fill: #611046;
}

.ct-series-z .ct-point,
.ct-series-z .ct-line,
.ct-series-z .ct-qar,
.ct-series-z .ct-slice-sonut {
  stroke: #9e7308;
}

.ct-series-z .ct-slice-pie,
.ct-series-z .ct-prea {
  fill: #9e7308;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #ebedf0;
  color: #453d3f;
  font-family: Roboto;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #ebedf0;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.cell-item {
}
