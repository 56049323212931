.container {
    position: fixed;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

.myId {
    margin-right: 5rem;
    border-radius: 5px;
    background: #c9d6ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #e2e2e2,
            #c9d6ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 2rem;
    display: grid;
    justify-content: center;
    align-content: center;
}

.call-button {
    text-align: center;
    margin-top: 2rem;
}

.video-container {
    position: relative;

}

.video-me {
    /* border: red solid 3px; */
    position: absolute;
    right: 0px;
    bottom: 0px;
    box-shadow: "0px 3px 7px 1px rgba(0,0,0,0.3)";
}

.video-from {
    /* border: green solid 3px; */
    position: absolute;
    right: 15px;
    bottom: 15px;
    box-shadow: "0px 3px 7px 1px rgba(0,0,0,0.3)";
}

.caller {
    position: fixed;
    bottom: 0px;
    right: 0;
    background: rgb(74, 180, 6);
    background: linear-gradient(90deg, rgba(74, 180, 6, 1) 0%, rgba(4, 98, 0, 1) 100%);
    text-align: center;
    color: #fff;
    padding: 22px;
    border-radius: 15px 0px 0px 0px;
}

@keyframes anima {
    to {
        opacity: 0;
    }
}

.callerEffect {
    animation: anima 750ms ease infinite;
}